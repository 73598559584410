
import { mixins } from "vue-class-component";
import { Vue, Component } from "vue-property-decorator";
import { ValidationMixin, InvalidMessages } from "@/mixins/validation-mixin";

// import Typed from 'typed.js'

interface User {
  userId: string,
  userPwd: string,
  firstName: string,
  lastName: string,
  userGroupId: string,
  pwdConfirm: string,
  companyName: string,
  billingAddress: string,
  billingContactEmail: string,
  currency: string,
}

@Component
export default class CreateUser extends mixins(Vue, ValidationMixin) {
  private form: User = {
    userId: '',
    userPwd: '',
    firstName: '',
    lastName: '',
    userGroupId: '',
    pwdConfirm: '',
    companyName: '',
    billingAddress: '',
    billingContactEmail: '',
    currency: '',
  };

  created() {
    this.load()
  }

  async load() {
    this.loading = true;
    try {
      this.form.currency = this.getLocale === 'ko-KR' ? 'KRW' : 'USD';
      const userGroupId = this.$route.params && this.$route.params.userGroupId;
      if (userGroupId) {
        const { result, data } = await this.axios.get(`/group/${ userGroupId }`)

        if (result) {
          const { userGroup, _id } = data;
          this.form.companyName = userGroup;
          this.form.userGroupId = _id;

          // not acceptable 이랑 구분하기 위해 조건을 result === false로 함..
        } else if (result === false) {
          await this.$router.push( { path: `/error/invalid-group` })
        }
      }
    } catch (e) {
      this.validationMessages(e);
    }
    this.loading = false;
  }

  async save() {
    this.proc(true);
    try {
      const { result, message } = await this.axios('/user',{
        method: 'post',
        data: {
          ...this.form,
        }
      })

      if (result) {
        this.proc(false);
        return this.$router.replace(
            { path: '/' },
            (to:any) => {
          to.params.create = true;
          to.params.message = message
        })
      } else {
        this.invalidMessages = message as InvalidMessages[];
      }
    } catch (e) {
      this.validationMessages(e);
    }
    this.proc(false);
  }
}
